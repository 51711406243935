import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import Herospace from "../../../assets/images/portfolio_items/CSwomen/CSwomen.png"
import CSwomen1 from "../../../assets/images/portfolio_items/CSwomen/CSwomen1.png"
import CSwomen2 from "../../../assets/images/portfolio_items/CSwomen/CSwomen2.png"
import CSwomen3 from "../../../assets/images/portfolio_items/CSwomen/CSwomen3.png"

export default () => 
<div>
    <SEO title={'ICON Worldwide | Credit Suisse - Community Engagement Project Suisse'}
    description="An interactive community engagement project to support the Swiss women team at the world cup: discover more on ICON's portfolio!"
    canonical={'https://icon-worldwide.com/works/womens-football'}/>
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={Herospace} alt="Swiss women team, Credit Suisse, ICON Worldwide, community engagement, portfolio" title="ICON Worldwide portfolio, community engagement, Credit Suisse, Swiss women team"/>
            <div className="title"><h1>Credit Suisse<span></span></h1>
            <h2>Interactive community engagement</h2>
            </div>
        </div>
        <div className="portfolio-inner">
        <p>To support the Swiss women team at the world cup, ICON design an interactive community platform aligned with the national campaign.</p>
        <div className="portfolio-images" id="long-page">
            <img src={CSwomen1} alt="Swiss women team, Credit Suisse, ICON Worldwide, community engagement, portfolio" title="ICON Worldwide portfolio, community engagement, Credit Suisse, Swiss women team"/>
            <img src={CSwomen2} alt="Swiss women team, Credit Suisse, ICON Worldwide, community engagement, portfolio" title="ICON Worldwide portfolio, community engagement, Credit Suisse, Swiss women team"/>
            <img src={CSwomen3} alt="Swiss women team, Credit Suisse, ICON Worldwide, community engagement, portfolio" title="ICON Worldwide portfolio, community engagement, Credit Suisse, Swiss women team"/>
        </div>
        <WorkFooter previous="swiss-finance-institute" next="kanebo-sensai"/>
        </div>
    </div>
</div>